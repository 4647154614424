import { HttpClient } from "@angular/common/http";
import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import {
  NavController,
  ToastController,
  AlertController,
} from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import Player from "@vimeo/player";
/**
 * Generated class for the PlayerPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: "page-player",
  templateUrl: "player.html",
  styleUrls: ["player.scss"],
})
export class PlayerPage implements AfterViewInit {
  item;
  id;
  @ViewChild("video", { read: ElementRef }) video: ElementRef;
  @ViewChild("iframe", { read: ElementRef }) iframe: ElementRef;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public toastCtrl: ToastController,
    public _categorias: CategoriasService,
    public loading: LoadingService,
    public alert: AlertController,
    public http: HttpClient
  ) {}

  async ngAfterViewInit() {
    this.item = this.pageParams.get("player", "item");
    if (!this.item) {
      this.item = {};
      (
        await this.toastCtrl.create({
          message: "Não foi possível reproduzir o conteúdo escolhido",
          duration: 3000,
        })
      ).present();
      this.navCtrl.pop();
      return;
    }

    this.id = this.item.vimeo.split("/");
    this.id = this.id.filter((piece) => piece);
    // tudo isso para caso tenha uma url do tipo http://vimeo.com/312321/?playback=true ai vai pegar realmente so o this.id do vthis.ideo
    this.id = this.id[this.id.length - 1].split("/")[0].split("?")[0];
    setTimeout(() => this.init(), 1);
  }

  navPop() {
    this.navCtrl.pop();
  }

  oniFrameLoad(event) {
    console.log(event);
    // event.path
  }
  init() {
    try {
      if (!this.item.vimeo) {
        throw "Nao tem link";
      }

      const player = new Player(this.video.nativeElement);
      player.ready().catch((e) => this.error("ready catch", e));

      player
        .setColor("#f15b08")
        .then((color) => console.log(color))
        .catch((error) => console.error(error));

      player.on("play", (e) => {
        console.log("play", e);
        this._categorias.visualizar(this.item.id).subscribe(
          (r: any) => {},
          (e) => player.pause()
        );
      });

      player.on("loaded", (e) => {
        console.log("loaded", e);
      });

      player.on("error", (e) => this.error("error", e));
    } catch (e) {
      this.error("try catch", e);
    }
  }

  async error(log = "", log_error = {}) {
    console.error(log, log_error);
    (
      await this.alert.create({
        header: "Oops",
        message:
          "Não foi possível carregar, por favor tente novamente mais tarde",
      })
    ).present();
    this.navCtrl.pop();
  }
}
