import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
} from "@angular/core";
import { AplicativosService } from "../services/aplicativos.service";

@Directive({
  selector: ".auth-pages",
})
export class AuthPagesDirective implements AfterViewInit {
  constructor(
    public aplicativosService: AplicativosService,
    public elementRef: ElementRef<HTMLElement>
  ) {
    this.aplicativosService.clearItem();
    this.aplicativosService.clearTheme();
  }

  ngAfterViewInit() {
    if (this.aplicativosService.appType === "ipe-saude") {
      const logosWrapper = document.createElement("div");
      logosWrapper.classList.add("logos-wrapper");

      const logoSergioLovato = document.createElement("img");
      logoSergioLovato.src = '/assets/imgs/sergio-lovato.png'
      logoSergioLovato.classList.add("sergio-lovato");

      const logoIpe = document.createElement("img");
      logoIpe.src = '/assets/imgs/ipe.png'
      logoIpe.classList.add("ipe-saude");

      logosWrapper.append(logoSergioLovato);
      logosWrapper.append(logoIpe);
      this.elementRef.nativeElement.append(logosWrapper);
    }
  }

  @HostBinding("class.auth-pages-ipe-saude") get appTypeIpeSaude() {
    return this.aplicativosService.appType === "ipe-saude";
  }
  @HostBinding("class.auth-pages-personal") get appTypePersonal() {
    return this.aplicativosService.appType === "personal";
  }
  @HostBinding("class.AuthPagesDirective") directiveHint = true;
}
