import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { AlertController, NavController, Platform } from "@ionic/angular";
import { AplicativosService } from "./aplicativos.service";
import { AuthService } from "./auth.service";
import { CategoriasService } from "./categorias.service";
import { GlobalService } from "./global.service";
import { HelpersService } from "./helpers.service";
import { PageParamsService } from "./page-params.service";

const { Browser } = Plugins;
/*
  Generated class for the PlayerService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class PlayerService {
  constructor(
    public http: HttpClient,
    public alert: AlertController,
    public navCtrl: NavController,
    public auth: AuthService,
    public global: GlobalService,
    public plt: Platform,
    public _categorias: CategoriasService,
    public aplicativos: AplicativosService,
    public helpers: HelpersService,
    public pageParams: PageParamsService
  ) {}

  async playerPage(item) {
    if (item.pode_ver) {
      this.openPlayerPage(item);
    } else {
      (
        await this.alert.create({
          header: "Ops...",
          message: "Para ver esse conteúdo você precisa adiquirir um plano",
          buttons: [
            {
              role: "cancel",
              text: "Ok",
              handler: () => {
                this.navCtrl.navigateForward("/contratar");
              },
            },
          ],
        })
      ).present();
    }
  }

  async openPlayerPage(item) {
    if (item.tipo == "pdf") {
      if (!item.thumbnail_principal) {
        (
          await this.alert.create({
            header: "Oops",
            message:
              "Não foi possível carregar, por favor tente novamente mais tarde",
          })
        ).present();
        return;
      }

      if (this.plt.is("ios")) {
        if (this.aplicativos.item.id == 1) {
          Browser.open({
            url: item.thumbnail_principal,
          });
        } else {
          window.open(item.thumbnail_principal, "_system");
        }
      } else {
        Browser.open({
          url: item.thumbnail_principal,
        });
        // window.open(item.thumbnail_principal, '_system');
      }

      this._categorias.visualizar(item.id).subscribe((e) => {
        item.assistido = 1;
      });
    } else {
      this.navCtrl.navigateForward("/player");
      this.pageParams.set("player", { item: item });
    }
  }
}
