import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NavController, ToastController } from "@ionic/angular";

import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-favoritos-content",
  templateUrl: "./favoritos-content.component.html",
  styleUrls: ["./favoritos-content.component.scss"],
})
export class FavoritosContentComponent implements OnInit {
  videos_open = true;
  audios_open = false;
  pdfs_open = false;
  videos = [];
  audios = [];
  pdfs = [];
  loading = false;

  @Output() onRequestEnd = new EventEmitter();

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public auth: AuthService,
    public toastCtrl: ToastController,
    public _categorias: CategoriasService,
    public aplicativos: AplicativosService
  ) {}

  // cada vez q a tab é aberta ele busca na api os favoritos do usuario

  ngOnInit() {
    this.getFavoritos();
    console.log("ngAfterViewInit AudiosPage");
  }

  getFavoritos() {
    this.loading = true;
    this.api
      .get(
        "/users/" +
          this.auth.user.user.id +
          "/favoritos?aplicativo_id=" +
          this.aplicativos.item.id
      )
      .subscribe(
        (r: any) => {
          this.loading = false;
          this.videos = r.filter((obj) => obj.tipo == "video");
          this.audios = r.filter((obj) => obj.tipo == "audio");
          this.pdfs = r.filter((obj) => obj.tipo == "pdf");
          this.onRequestEnd.emit();
        },
        (e) => {
          this.loading = false;
          this.onRequestEnd.emit();
        }
      );
  }

  favoritar(item) {
    this.videos = this.videos.filter((obj) => obj.id != item.id);
    this.audios = this.audios.filter((obj) => obj.id != item.id);
    this.pdfs = this.pdfs.filter((obj) => obj.id != item.id);
    // this._categorias.favoritar(item);
  }
}
