import { AfterViewInit, Component } from "@angular/core";
import { NavController } from "@ionic/angular";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { CategoriasService } from "src/app/services/categorias.service";

@Component({
  selector: "app-categorias",
  templateUrl: "categorias.component.html",
  styleUrls: ["categorias.component.scss"],
})
export class CategoriasComponent implements AfterViewInit {
  nutri = this.aplicativos.getNutri();

  constructor(
    private navCtrl: NavController,
    public _categorias: CategoriasService,
    public aplicativos: AplicativosService
  ) {}

  ngAfterViewInit() {
    console.error("DOI FAILL HERE");
    this.getCategorias();
  }
  getCategorias() {
    this._categorias.getAll({ completo: false });
  }

  categoriasConteudosPage(item) {
    this._categorias.current = item;
    this.navCtrl.navigateForward("/categorias-conteudos");
  }

  nutriPage() {
    this.aplicativos.setItem(this.nutri);
    this.navCtrl.navigateRoot(this.aplicativos.getInitialPage(this.nutri));
  }
}
